<template>
  <v-container fluid class="pa-0 ma-0">
    <hero></hero>
    <matr></matr>
  </v-container>
</template>

<script>
import Hero from '@/components/matriculando/Hero.vue'
import Matr from '@/components/matriculando/Matr.vue'

export default {
  components: {
    Hero,
    Matr,
  },
}
</script>
